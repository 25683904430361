<template>
  <div class="category-container">
    <section>
      <div class="container-fluid">
        <h1 class="header">
          {{ $route.query.t || title }}
        </h1>
      </div>

      <div
        v-show="!is_loading"
        class="cards-container"
        :class="itemImageShape"
      >
        <div v-for="(item, index) in normalizedItems" :key="item.id">
          <carousel-item
            :item="item"
            :item-type="itemImageShape"
            :position="index"
            @slide-click="onItemClick(item, index, $event)"
          />
        </div>
      </div>
    </section>

    <div v-show="is_loading" class="loader-container">
      <loader color="#798B96" />
    </div>

    <div v-if="!is_loading && normalizedItems.length < 1" class="loader-container">
      <p>No items left</p>
    </div>

    <!--    <div class="pagination-container">-->
    <!--      <v-pagination-->
    <!--        v-if="pagination"-->
    <!--        v-model="page"-->
    <!--        :length="paginations_count"-->
    <!--      />-->
    <!--    </div>-->
  </div>
</template>

<script>
  import { amsClient } from '@/service/ams';
  import CarouselItem from '@/components/swiper-carousel/carousel-item/carousel-item';
  import { matchString } from '@/utils/matchString';
  import Auth from '@/service/authService';
  import Config from '@/service/config';
  import Loader from '@/components/loader/loader';
  import rtm from '@/service/realTimeMessages';
  import { debounce } from 'lodash';
  import normaliseMetaTags from '@/utils/normaliseMetaTags';

  export default {
    name: 'AutoSegmentsContainer',
    components: {
      CarouselItem,
      Loader,
    },
    data() {
      return {
        pagination: false,
        page: 1,
        paginations_count: 2,
        is_loading: true,
        add_new_pagination_page: true,
        default_link: '',
        items: [],
        // title: 'Items',
        // item_image_shape: 'circle',
        aspect_ratio: '1x1',
        url: '',
        metadata: {},
      };
    },
    computed: {
      tarId() {
        return this.$route.query?.tarId || '';
      },
      prid() {
        return Config.get('provider_id');
      },
      title() {
        return this.$route.meta.title;
      },
      isUpcomingSegment() {
        return this.$route.name === 'upcoming';
      },
      isUploadsSegment() {
        return this.$route.name === 'uploads';
      },
      isPopularVideosSegment() {
        return this.$route.name === 'popular-videos';
      },
      isOnAirSegment() {
        return this.$route.name === 'onair';
      },
      isChannelsSegment() {
        return this.$route.name === 'channels';
      },
      isDepartmentsSegment() {
        return this.$route.name === 'departments';
      },
      isProductsSegment() {
        return this.$route.name === 'new-arrivals';
      },
      isRecentProductsSegment() {
        return this.$route.name === 'recently-watched-products';
      },
      isRecentlyWatchedSegment() {
        return this.$route.name === 'recently-watched-videos';
      },
      // prid() {
      //   return Config.get('provider_id');
      // },
      normalizedItems() {
        return this.items.map((item) => {
          item.title = item.title || item.name;
          item.itype = this.sectionType;
          return item;
        });
      },
      sectionType() {
        if (this.isRecentProductsSegment || this.isProductsSegment) {
          return 'shop_product';
        }
        if (
          this.isUploadsSegment
          || this.isPopularVideosSegment
          || this.isRecentlyWatchedSegment
        ) {
          return 'item_mov_vod_shoprz';
        }
        if (this.isOnAirSegment || this.isUpcomingSegment) {
          return 'item_epg_shop_event';
        }
        return 'screen_shoprz_page';
      },
      itemImageShape() {
        if (this.isChannelsSegment) {
          return 'circle';
        }
        if (
          this.isRecentProductsSegment
          || this.isProductsSegment
        ) {
          return 'vertical';
        }
        return 'horizontal';
      },
      filterLink() {
        if (this.isOnAirSegment) {
          const url = `/on_air/`;
          return url + (this.tarId && `?filters=target_id:${this.tarId}`);
        }
        if (this.isUpcomingSegment) {
          const url = `/coming_soon/`;
          return url + (this.tarId && `?filters=target_id:${this.tarId}`);
        }
        if (this.isUploadsSegment) {
          const url = `/filter/item_mov_vod_shoprz/?filters=provider_id:${this.prid}`;
          return url + (this.tarId && `@target_id:${this.tarId}`) + '&start=0&sort=-created_at';
        }
        if (this.isPopularVideosSegment) {
          const url = `/filter/item_mov_vod_shoprz/?filters=provider_id:${this.prid}`;
          return url + (this.tarId && `@target_id:${this.tarId}`) + '&start=0&sort=-views';
        }
        if (this.isRecentlyWatchedSegment) {
          return `/stk/get/recent`;
        }
        if (this.isRecentProductsSegment) {
          return `/stk/get/recent_shop`;
        }
        if (this.isProductsSegment) {
          return `/filter/shop_product/?filters=provider_id:${this.prid}&start=0&sort=-created_at`;
        }
        if (this.isChannelsSegment) {
          return `/filter/store_channels/?filters=provider_id%3A${this.prid}&start=0&sort=-updated_at&d=gstweb`;
        }
        if (this.isDepartmentsSegment) {
          return `/filter/departments/?filters=provider_id%3A${this.prid}&start=0&sort=-updated_at&d=gstweb`;
        }
        return '';
      },
    },
    watch: {
      '$route.name': function(val) {
        this.items = [];
        this.fetchItems();
      },
    },
    async mounted() {
      await this.fetchItems();

      this.$bus.$emit('updateTitle', this.$route.query.t || this.title);
    },
    methods: {
      async onItemClick(item, index, $event) {
        const newItem = { ...item };

        if (typeof newItem.creator === 'string') {
          if (newItem.storefront) {
            newItem.creator = { ...newItem.storefront };
          } else {
            const response = await amsClient.callAms(newItem.creator);
            newItem.creator = { ...response.head };
          }
        }

        if (newItem?.itype?.includes('vod')) {
          //   // const path = this.$route.path !== '/' ? this.$route.path : '';
          //   this.$bus.$emit('togglePopupVOD', {
          //     item: {
          //       ...newItem,
          //       // ...(!newItem.share && {
          //       //   share: `${path}/v/${newItem.slug}/`,
          //       // }),
          //     },
          //     options: {
          //       startPosition: ($event?.time_in || 0) / 1000,
          //     },
          //   });
          //   this.$bus.$emit('headerHide');
          this.$router.push(`/s/${newItem.storefront?.slug}/v/${newItem.slug || newItem.id}`);
          // this.$router.push(`/v/${newItem.slug || newItem.id}`);
        }
      },
      async fetchItems() {
        const { items } = await amsClient.callAms(this.filterLink);
        this.items = items;
        this.is_loading = false;
      },
    },
  };
</script>

<style scoped lang="scss">
  .header {
    margin-top: 30px;
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
  }

  .category-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .cards-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(260px, 260px));
    justify-content: center;
    gap: 16px;
    height: 100%;
    padding-right: 15px;
    padding-left: 15px;

    &.vertical {
      grid-template-columns: repeat(auto-fit, minmax(159px, 159px));
    }

    &.circle {
      grid-template-columns: repeat(auto-fit, minmax(135px, 135px));
    }

    &.horizontal {
      grid-template-columns: repeat(auto-fit, minmax(260px, 260px));
    }
  }

  .store-item {
    list-style-type: none;
    margin-left: 20px;
    margin-bottom: 50px;
    width: 135px;
  }

  .pagination-container {
    max-width: 500px;
    margin: 0 auto 45px auto;
  }

  .loader-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
