<template>
  <auto-segments-container/>
</template>

<script>
  import AutoSegmentsContainer from './auto-segments.container.vue';

  export default {
    name: 'AutoSegmentsView',
    components: {
      AutoSegmentsContainer,
    },
  };
</script>

<style></style>
